@import "../global/grid.scss";
@import "../framework/grid.scss";

.Navbar{
    &-Overlay{
         position: fixed;
         width: 100%;
         height: 100%;
         top:0px;
         left:0px;
         right:0px;
         bottom:0px;
         background: #000000;
         opacity: 0;
         display: none;
         z-index: 100;
         transition:opacity 1s;

         &-Active{
            opacity: 0.3;
            display: block!important;
         }
    }

    &-Container{
        position: relative;
        display: inline-flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 100%;

        &-Logo {
            width: 41.896px;
            height: 32px;
            cursor: pointer;

            @include media-breakpoint-up(sm) {
                width: 120px;
                height: 24px;
            }

            @include media-breakpoint-up(md) {
                width: 160px;
                height: 32px;
            }

            @include media-breakpoint-up(xl) {
                width: 200px;
                height: 40px;
            }

            &-Icon{
                width: inherit;
            }

            &-Icon-Image{
                width: 41.896px;
                height: 32px;
                fill: url(#mobilePattern);

                @include media-breakpoint-up(sm) {
                    fill: url(#desktopPattern);
                    width: 120px;
                    height: 24px;
                }

                @include media-breakpoint-up(md) {
                    width: 160px;
                    height: 32px;
                }

                @include media-breakpoint-up(xl) {
                    width: 200px;
                    height: 40px;
                }
            }
        }

        &-Menu{
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            display: none;
            align-items: center;
            justify-content: flex-end;
            color: #38637B;
            width: inherit;

            & ul{
                list-style: none;
            }

            &-Item{
                display: inline-block;
                cursor: pointer;


                &-Link{
                    padding: 11px 12px;
                    color: #38637B;
                    text-decoration: none;

                    &-Active{
                        border-bottom: 2px solid #38637B;
                    }
                }
            }

            &-MobileShown{
                display: block;
                border: 1px solid #ccc;
                position: absolute;
                width: 250px;
                top: 100px;
                background: #fff;
                z-index: 1000;
                border-radius: 10px;

                &-Item{
                    display: block;

                    &:first-child a{
                        border-radius: 10px 10px 0 0;
                    }

                    &:last-child a{
                        border-radius: 0 0 10px 10px;
                    }

                    &-Link{
                        display: block;
                        padding: 20px 20px;
                        text-decoration: none;
                        font-family: 'Inter', sans-serif;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        color: #38637B;
                        border-bottom: none;
                        cursor: pointer;

                        &:hover{
                            background: #EBEFF2;
                        }

                    }
                }
            }
            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }

        &-LangSelector{
            width: 40px;

            @include media-breakpoint-up(md) {
                width: 84px;
            }
        }

        &-MobileMenu{
            z-index:1000;

            @include media-breakpoint-up(lg) {
                display: none;
            }

            &-Icon{
                width: 40px;
                height: 40px;
                border-radius: 20px;
                background: rgba(235, 239, 242, 0.25);
                border: 1px solid #EBEFF2;
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                & span{
                    width: 18px;
                    height: 2px;
                    background: #38637B;
                    border-radius: 2px;
                    display: block;
                    margin: 2px 0px;
                    transition: all 0.4s;
                }
            }

            &-Opened .MobileIcon{
                background: #EBEFF2;
            }

            &-Opened .MobileIcon span:nth-child(1){
                transform: translate(4px, -1px) rotate(45deg);
                transform-origin: left top;
                width: 20px;
            }

            &-Opened .MobileIcon span:nth-child(2){
                transform: scale(0);
            }

            &-Opened .MobileIcon span:nth-child(3){
                transform: translate(-2px, -14px) rotate(-45deg);
                transform-origin: right bottom;
                width: 20px;
            }
        }
    }

}

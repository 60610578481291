@import "../global/grid.scss";
@import "../framework/grid.scss";

.LatestNews{
    &-Container {
        margin: 0px 24px;

        @include media-breakpoint-up(md) {
            margin: 0px 36px;
        }
        @include media-breakpoint-up(lg) {
            margin: 0px 40px;
        }

        @include media-breakpoint-up(ulg) {
            margin: 0px 80px;
        }

        @include media-breakpoint-up(xl) {
            margin: 0px 160px;
        }

        @include media-breakpoint-up(hd) {
            margin: 0px 400px;
        }
    }

    &-Title {
        font-family: 'Montserrat Alternates', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #23312A;
        margin: 0px 0px 24px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;

        @include media-breakpoint-up(md) {
            font-size: 28px;
            margin-bottom: 32px;
            align-items: center;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            line-height: 40px;
            margin: 0px 0px 40px;
            gap: 16px;
        }

        @include media-breakpoint-up(ulg) {
            font-size: 32px;
        }
    }
}

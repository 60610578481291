.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.action {
  width: 100%;
  border-radius: 12px;
  padding: 14px 24px 14px 24px;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  background-color: var(--color-B1000);
  color: var(--color-N0);
  cursor: pointer;
  appearance: none;
}

.label {
  font-family: inherit;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

.actionIcon {
  width: 32px;
  height: 32px;
}

@media (min-width: 744px) {
  .root {
    max-width: 416px;
  }
}

@media (min-width: 1440px) {
  .action {
    font-size: 16px;
  }

  .root {
    padding: 32px;
  }
}

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 34px;
  box-sizing: border-box;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33% - 20px);
  margin: 0 0 32px;
  box-sizing: border-box;
  outline: 0.5px solid var(--color-B200);
  background-color: rgba(235, 239, 242, 0.25);
  border-radius: 24px;
  color: initial;
  text-decoration: none;
  cursor: pointer;
}

.imageBox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: content-box;
  border-radius: 24px 24px 0 0px;
  overflow: hidden;
  height: 200px;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 24px 24px 0 0px;
  object-fit: cover;
}

.newsBox {
  width: 100%;
  max-height: 224px;
  padding: 30px;
}

.title {
  color: #0B1A12;
  font-family: 'Montserrat Alternates';
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  -webkit-line-clamp: 3; /* Многоточие после 4 строки */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .title {
    font-size: 17px;
    line-height: 26px;
  }
}

@media (max-width: 1120px) {
  .item {
    width: calc(50% - 20px);
  }
}

@media (max-width: 744px) {
  .item {
    width: 100%;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .newsBox {
    max-height: auto;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    -webkit-line-clamp: 2; /* Многоточие после 2 строки */
  }
}

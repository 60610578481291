.root {
  width: 100%;
  margin-bottom: 34px;
  box-sizing: border-box;
}

.item {
  display: flex;
  margin: 0 0 32px;
  padding: 32px;
  box-sizing: border-box;
  background-color: rgba(235, 239, 242, 0.25);
  box-shadow: inset 0 0 0 0.5px var(--color-B200);
  border-radius: 24px;
  color: initial;
  text-decoration: none;
  cursor: pointer;
}

.noNews {
  color: #23312A;
  font-family: 'Montserrat Alternates';
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 10px;
}

.imageBox {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 32px;
  box-sizing: border-box;
  border-radius: 16px;
  min-width: 352px;
  max-width: 352px;
  min-height: 224px;
  max-height: 224px;
}

.image {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newsBox {
  max-height: 224px;
}

.date {
  color: #545F59;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom: 12px;
}

.title {
  margin: 0 0 8px;
  color: #0B1A12;
  font-family: 'Montserrat Alternates';
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}

.description {
  color: #545F59;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;

  -webkit-line-clamp: 4; /* Многоточие после 4 строки */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .root {
    margin-bottom: 14px;
  }

  .item {
    padding: 23px;
  }

  .imageBox {
    margin-right: 24px;
    min-width: 304px;
    max-width: 304px;
    min-height: 200px;
    max-height: 200px;
  }

  .newsBox {
    max-height: 200px;
  }

  .date {
    margin-bottom: 8px;
    font-size: 12px;
  }

  .title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 4px;
    -webkit-line-clamp: 3; /* Многоточие после 3 строки */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .description {
    font-size: 14px;
    line-height: 20px;
    -webkit-line-clamp: 5;
  }
}

@media (max-width: 868px) {
  .date {
    margin-bottom: 7px;
  }

  .imageBox {
    min-width: 264px;
    max-width: 264px;
    min-height: 176px;
    max-height: 176px;
  }
}


@media (max-width: 744px) {
  .item {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .newsBox {
    max-height: auto;
  }

  .imageBox {
    margin-right: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    max-height: 200px;
    margin-bottom: 24px;
  }

  .image {
    max-width: 354px;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
    -webkit-line-clamp: 2; /* Многоточие после 2 строки */
  }

  .description {
    font-size: 13px;
    line-height: 20px;
    -webkit-line-clamp: 3;
  }
}

@media (max-width: 744px) {
  .item {
    flex-direction: column;
  }

  .imageBox {
    margin-right: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    max-height: 200px;
    margin-bottom: 24px;
  }

  .image {
    max-width: 100%;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
    -webkit-line-clamp: 3;
  }

  .description {
    font-size: 13px;
    line-height: 20px;
    -webkit-line-clamp: 3;
  }
}

@media (max-width: 460px) {
  .root {
    margin-bottom: 0px;
  }

  .imageBox {
    margin-right: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: auto;
  }

  .image {
    max-width: 100%;
  }
}

@import "../global/grid.scss";
@import "../framework/grid.scss";

.Button{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    padding: 12px 20px;
    background: #38637B;
    border-radius: 24px;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;

    &:hover{
        background: #608295;
    }

    &:active{
        background: #294A59;
    }

    &-greenWithIconUp{
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        background: #347757;
        border-radius: 32px;
        padding: 14px 20px 14px 14px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        gap: 4px;
        width: 100%;
        max-width: 132px;

        &::before{
            content: url(../../static/icons/button-arrow-up.svg);
            width: 20px;
            height: 20px;
        }

        &:hover{
            background: #488568;
        }

        &:active{
            background: #275941;
        }

        @include media-breakpoint-up(sm) {
            max-width: 187px;
            font-size: 16px;
        }

        @include media-breakpoint-up(ulg) {
            padding: 20px 28px 20px 28px;
            font-size: 20px;
        }
    }

    &-grayWithIconDown{
        padding: 14px 20px 14px 14px;
        background: rgba(235, 239, 242, 0.25);
        border: 1px solid #EBEFF2;
        border-radius: 32px;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #38637B;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        gap: 4px;
        width: 100%;
        max-width: 132px;

        &::before{
            content: url(../../static/icons/button-arrow-down.svg);
            width: 20px;
            height: 20px;
        }
        &:hover{
            background: #EBEFF2;
        }

        &:active{
            background: #D7E0E5;
            color: #294A59;
        }

        @include media-breakpoint-up(sm) {
            max-width: 187px;
            font-size: 16px;
        }

        @include media-breakpoint-up(ulg) {
            padding: 20px 28px 20px 28px;
            font-size: 20px;
        }
    }

    &-greenWithIconCall{
        font-weight: 600;
        font-size: 16px;
        font-style: normal;
        line-height: 20px;
        color: #FFFFFF;
        background: #347757;
        border-radius: 32px;
        padding: 14px 20px 14px 14px;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        &::before{
            content: url(../../static/icons/contacts/mail_white.svg);
            width: 20px;
            height: 20px;
            margin-right: 4px;
        }

        @include media-breakpoint-up(md) {
            max-width: 200px;
        }
    }

    &-appStore {
      color: #FFFFFF;
      background: #0B1A12;
      padding: 11px 24px 11px 24px;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      gap: 4px;
      width: 100%;
      white-space: nowrap;

      @include media-breakpoint-up(sm) {
        min-width: 0;
      }

      @include media-breakpoint-up(md) {
        min-width: 152px;
      }

      &:hover {
        background: #0B1A12;
        opacity: .9;
      }

      &:active {
        background: #000;
      }

      &::before {
        content: url(../../icons/applePay.svg);
        width: 18px;
        height: 18px;
      }

      .Button-Title {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }

    &-googlePlay {
      color: #FFFFFF;
      background: #0B1A12;
      padding: 11px 24px 11px 24px;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      gap: 4px;
      width: 100%;
      white-space: nowrap;

      @include media-breakpoint-up(sm) {
        min-width: 0;
      }

      @include media-breakpoint-up(md) {
        min-width: 152px;
      }

      &:hover{
        background: #0B1A12;
        opacity: .9;
      }

      &:active{
        background: #000;
      }

      &::before{
        content: url(../../icons/googlePlay.svg);
        width: 16px;
        height: 16px;
      }

      .Button-Title{
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }

    &-TitlePartToHide:first-child{
      display: none;
    }

    @include media-breakpoint-up(md) {
      &-TitlePartToHide:last-child{
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      &-TitlePartToHide:first-child{
        display: block;
      }
      &-TitlePartToHide:last-child{
        display: block;
      }
    }
}

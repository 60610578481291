.title {
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
}

.subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.agreementLink {
  color: inherit;
}

@media (min-width: 1440px) {
  .icon {
    transform: scale(1.3334);
  }

  .title {
    font-size: 22px;
    line-height: 32px;
  }

  .subtitle {
    font-size: 14px;
  }
}

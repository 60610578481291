@import "../global/grid.scss";
@import "../framework/grid.scss";

.ContactsItem{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;


    &-Contact{
        padding: 14px 20px 14px 14px;
        width: 100%;
        height: 48px;
        background: rgba(235, 239, 242, 0.25);
        border: 1px solid #EBEFF2;
        border-radius: 32px;
        color: #38637B;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        justify-content: center;
        gap: 2px;

        @include media-breakpoint-up(md) {
            max-width: 210px;
            width: fit-content;
        }

        &::before{
            content: "";
            display: block;
            margin: 0px 2px 0px 0px;
            width: 20px;
            height: 20px;
        }

        &-phone::before{
            background: url('../../static/icons/contacts/phone.svg');
        }

        &-mail::before{
            background: url('../../static/icons/contacts/mail.svg');
        }
    }

    &-Hint{
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #545F59;
        margin-top: 16px;
    }

    @include media-breakpoint-up(md) {
        &-Hint{
            margin: 0px 0px 0px 16px;
        }
    }

}

@include media-breakpoint-up(md) {
    .ContactsItem{
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}
.root {
  display: flex;
  padding: 8px 12px;
  color: var(--color-B1000);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  background-color: white;
  border-radius: 16px;
  box-shadow: inset 0 0 0 1px var(--color-B100);
  cursor: pointer;
  flex-wrap: nowrap;
  gap: 4px;
  justify-content: center;
}

.icon {
  --size: 16px;

  display: block;
  width: var(--size);
  height: var(--size);
  flex-grow: 0;
  flex-shrink: 0;
}

@import "../global/grid.scss";
@import "../framework/grid.scss";

.BlockToMap{
    text-align: center;
    margin: 48px 24px;

    @include media-breakpoint-up(md) {
        margin: 64px 48px;
    }

    @include media-breakpoint-up(lg) {
        margin: 96px 176px;
    }

    @include media-breakpoint-up(xl) {
        margin: 128px 160px;
    }

    &-Title{
        font-family: 'Montserrat Alternates', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #23312A;

        @include media-breakpoint-up(md) {
            font-size: 28px;
            line-height: 36px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    &-Text{
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #545F59;
        margin-top: 4px;

        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 24px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 8px;
        }
    }

    &-Button{
        margin-top: 32px;

        & .Button{
            text-decoration: none;
            cursor: pointer;
            padding: 14px 24px;
            border-radius: 12px;

            @include media-breakpoint-up(md) {
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
            }
        }
    }
}
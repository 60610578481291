@import "../global/grid.scss";
@import "../framework/grid.scss";

.TopBanner{
    padding: 16px 24px 24px;
    background: rgba(235, 239, 242, 0.25);
    border: 1px solid #EBEFF2;
    border-radius: 24px;
    margin-bottom: 40px;

    @include media-breakpoint-up(sm) {
        padding: 24px;
        margin: 24px 24px 40px;
    }

    @include media-breakpoint-up(md) {
        padding: 24px;
        margin: 32px 48px 40px;
    }

    @include media-breakpoint-up(lg) {
        padding: 32px;
        margin: 64px 40px 40px;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 120px 160px 128px;
    }

    &-Container{
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
            gap: 24px;
        }

        &-Left{
            &-Banner-Image {
                width: 200px;
                height: 200px;
                border-radius: 140px;
    
                @include media-breakpoint-up(lg) {
                    width: 280px;
                    height: 280px;
                }
            }
        }

        &-Right{
            @include media-breakpoint-up(lg) {
                margin-left: 32px;
            }

            &-Title{
                font-family: 'Montserrat Alternates', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.5px;
                color: #0B1A12;
                text-align: center;

                @include media-breakpoint-up(md) {
                    font-size: 28px;
                    line-height: 40px;
                    text-align: left;
                }

                &-NoImage{
                    @include media-breakpoint-up(lg) {
                        font-size: 34px;
                        line-height: 48px;
                    }
                }
            }

            &-Subtitle{
                font-family: 'Inter', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #545F59;
                margin-top: 8px;
                text-align: center;

                @include media-breakpoint-up(md) {
                    font-size: 20px;
                    line-height: 28px;
                    text-align: left;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 22px;
                    line-height: 36px;
                }
            }

            &-Button{
                margin-top: 20px;
                text-align: center;

                @include media-breakpoint-up(md) {
                    margin-top: 24px;
                    text-align: left;
                }

                @include media-breakpoint-up(lg) {
                    margin-top: 40px;
                }
            }
        }
    }
}


.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .root {
    padding: 24px 0 58px 0;
  }
}

@media (max-width: 868px) {
  .root {
    padding: 24px 0 58px 0;
  }
}

@media (max-width: 744px) {
  .root {
    padding: 24px 24px 58px 24px;
  }
}

@media (max-width: 460px) {
  .root {
    padding: 24px 24px 38px 24px;
  }
}

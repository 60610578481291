.root {
  width: 100%;
  max-width: 352px;
  padding: 14px 24px;
  background-color: rgba(56, 99, 123, 1);
  border: none;
  border-radius: 12px;
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.root:hover {
  background-color: #608295;
}

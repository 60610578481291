@import "../global/grid.scss";
@import "../framework/grid.scss";

.BorderBlock{
    &-BottomMargin{
        margin-bottom: 32px;
    }

    &-Green{
        background: #FFFFFF;
        border: 0.5px solid #71A089;

    &-Title {
      color: #275941;
    }
  }

    &-Orange{
        background: #FFFFFF;
        border: 0.5px solid #F5CC71;

    &-Title {
      color: #a6582d;
    }
  }

    &-Title{
        font-family: 'Montserrat Alternates', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        border-bottom: 0.5px solid #D6E4DD;
        padding-bottom: 24px;
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
            font-size: 24px;
        }
    }

    &-Content{
        &-BoldText{
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #23312A;
            margin-bottom: 24px;
        }
    }
}

.BorderBlock-Container{
    border-radius: 24px;
    margin: 24px;
    padding: 32px;

    @include media-breakpoint-up(md) {
        margin: 48px;
    }

    @include media-breakpoint-up(lg) {
        margin: 32px 40px;
    }

    @include media-breakpoint-up(xl) {
        margin: 32px 160px;
    }
}
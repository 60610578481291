.root {
  display: flex;
  margin-bottom: 44px;
  padding-top: 43px;
  justify-content: center;
}

@media (max-width: 1024px) {
  .root {
    padding-top: 34px;
    margin-bottom: 24px;
  }
}

@media (max-width: 868px) {
  .root {
    margin-bottom: 17px;
    padding: 0 24px;
  }
}

@media (max-width: 744px) {
  .root {
    padding-top: 23px;
  }
}

@media (max-width: 460px) {
  .root {
    margin-bottom: 0px;
  }
}

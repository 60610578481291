.root {
  display: inline-flex;
  padding: 2px;
  font-size: 14px;
  background-color: var(--color-B100);
  border-radius: 12px;
  box-shadow: inset 0 0 0 0.5px var(--color-B200);
}

.item {
  min-width: 116px;
  padding: 9px 24px;
  color: var(--color-B900);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.item:hover {
  color: #294A59;
}

.isSelected {
  background-color: var(--color-N0);
  border-radius: 10px;
  box-shadow:
    inset 0 0 0 0.5px var(--color-B200),
    0px 1px 4px rgba(41, 74, 89, 0.15);
  color: var(--color-DB);
}

@media (max-width: 460px) {
  .item {
    padding: 7px 28px;
  }

  .isSelected {
    padding: 7px 24px;
  }
}

:global(:root) {
  --color-B1000: #38637b;
  --color-B900: #4c7388;
  --color-B800: #608295;
  --color-B700: #7492a3;
  --color-B300: #c3d0d7;
  --color-B200: #d7e0e5;
  --color-B100: #ebeff2;

  --color-DB: #294a59;

  --color-N900: #23312a;
  --color-N800: #3c4841;
  --color-N700: #545f59;
  --color-N600: #6d7671;
  --color-N200: #ced1d0;
  --color-N150: #dadddb;
  --color-N100: #e7e8e7;
  --color-N50: #f3f4f3;
  --color-N25: #f9f9f9;
  --color-N0: #fff;
}

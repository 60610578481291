@import "../global/grid.scss";
@import "../framework/grid.scss";

.CountryBlock{
    &-Container{
        margin: 0px auto;
    }

    &-Title{
        font-family: 'Montserrat Alternates', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #23312A;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(md) {
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 24px;
        }

        &-Logo {
            margin: 3px 15px 0px 0px;
        }
    }

    &-Items{
        display: flex;
        justify-content: center;
        margin-bottom: 64px;
        gap: 8px;

        @include media-breakpoint-up(md) {
            gap: 16px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 96px;
        }

        @include media-breakpoint-up(ulg) {
            margin-bottom: 128px;
        }

        &-Country{
            &-Image{
                width: 40px;
                height: 40px;
                background: rgba(235, 239, 242, 0.25);
                border: 0.5px solid #EBEFF2;
                border-radius: 32px;
                padding: 4px;

                @include media-breakpoint-up(md) {
                    width: 64px;
                    height: 64px;
                    padding: 16px;
                }

                &  img{
                    width: 32px;
                    height: 32px;
                }

                &-Soon img{
                    opacity: 0.4;
                }
            }

            &-Hint{
                color: #858C88;
                text-align: center;
                font-size: 10px;
                line-height: 12px;
                margin-top: 8px;
            }
        }
    }

    &-Items-Country-Header{
        width: 84px;
        height: 40px;
        background: rgba(235, 239, 242, 0.25);
        border: 1px solid #EBEFF2;
        border-radius: 24px;
        padding: 12px;

        @include media-breakpoint-down(md) {
            width: 40px;
        }
    }

    &-Items-Country-Header-Toggler{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        cursor: pointer;
    }

    &-Items-Country-Header-Toggler-Language{
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #38637B;
        text-transform: uppercase;
    }

    &-Items-Country-Header-Toggler-Icon{
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &-Items-Country-Header-Toggler-Icon-Opened{
        transform: rotate(180deg);
    }
}

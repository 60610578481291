@import "../global/grid.scss";
@import "../framework/grid.scss";

.Locations{
    padding: 24px;
    background: rgba(235, 239, 242, 0.25);
    border: 1px solid #EBEFF2;
    border-radius: 24px;

    @include media-breakpoint-up(sm) {
        margin: 24px;
    }

    @include media-breakpoint-up(md) {
        margin: 40px 48px 64px;
    }

    @include media-breakpoint-up(lg) {
        margin: 40px 40px 96px;
    }

    &-Container{
        &-Filter{
            &-Option{
                margin-bottom: 16px;

                &-Switcher{
                    margin-bottom: 16px;

                    @include media-breakpoint-up(xl) {
                        margin-bottom: 18px;
                    }
                }

                &-Label{
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    color: #23312A;
                    margin: 0px 0px 8px 12px;
                }
            }
        }


        &-Divider{
            margin: 32px 0px;
            height: 0.5px;
            background: #C3D0D7;

            @include media-breakpoint-up(md) {
                margin: 48px 0px;
            }
        }

        &-Results-MapBlock{
            border: 0.5px solid #D7E0E5;
            border-radius: 12px;
            overflow: hidden;
            -moz-border-radius: 12px; /* Firefox */
            -webkit-border-radius: 12px; /* Safari, Chrome */
            -khtml-border-radius: 12px; /* KHTML */
            position: relative;
            -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        }

        &-Results-ListBlock{
            background: #FFFFFF;
            border: 0.5px solid #DADDDB;
            border-radius: 12px;
            display: table;
            width: 100%;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #0B1A12;

            &-Header{
                display: table-header-group;
                font-family: 'Inter', sans-serif;
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                color: #545F59;
            }

            &-Row{
                display: table-row;
            }

            &-Cell{
                display: table-cell;
                width: 25%;
                padding: 16px;
                border-bottom: 0.5px solid #F3F4F3;

                &-Comment{
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #545F59;
                }

                & a{
                    color: #38637B;
                    text-decoration: none;
                }
            }

            &-Cell:first-child{
                padding-left: 32px;
            }

            &-NotFound{
                text-align: center;
                padding: 48px 32px;
                font-family: 'Montserrat Alternates', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                color: #858C88;

                &-Icon{
                    margin-bottom: 16px;
                }
            }
        }

        &-Results-Mobile-ListBlock{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: flex-start;
            background: #FFFFFF;
            border: 0.5px solid #DADDDB;
            border-radius: 12px;
            width: 100%;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #0B1A12;
            margin: 8px 0px;
            padding: 24px;
        }

        &-Results-Mobile-ListBlock-Item{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            border-bottom: 0.5px solid #E3E6EA;
            padding: 12px 0px;
            width: 100%;
        }

        &-Results-Mobile-ListBlock-Item:first-child{
            padding-top: 0;
        }

        &-Results-Mobile-ListBlock-Item:last-child{
            border-bottom: none;
            padding-bottom: 0;
        }

        &-Results-Mobile-ListBlock-Item-Comment{
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #545F59;
        }

        &-Results-Mobile-ListBlock-Item-Header{
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
            color: #545F59;
        }

        &-Results-Mobile-ListBlock-Item-Phone{
            color: #38637B;
            text-decoration: none;
        }
    }
}
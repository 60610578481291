.Select-Box{

    &__control{
        background: #EBEFF2!important;
        border: 0.5px solid #D7E0E5!important;
        border-radius: 8px!important;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #38637B;

        &:hover{
            border: 1px solid #608295!important;
        }

        &:focus{
            border: 1px solid #608295!important;
            box-shadow: inset 0px 0px 0px 4px #C3D0D7!important;
        }
    }
}

.Select-Box__option{
    padding-left: 40px !important;
}

.Select-Box__option-selected{
    padding-left: 19px !important;
    
    &::before{
        content: url(../../static/icons/check.svg);
        padding-right: 8px;
    }
}
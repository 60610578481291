@import "../global/grid.scss";
@import "../framework/grid.scss";

.Switcher{
    &-Block{
        display: inline-flex;
        font-size: 14px;
        color: #4C7388;
        background: #EBEFF2;
        border: 0.5px solid #D7E0E5;
        border-radius: 12px;
        padding: 2px;
        width: 100%;


        &-Button{
            padding: 9px 24px;
            color: #4C7388;
            cursor: pointer;
            text-align: center;
            width: 50%;

            &-Active{
                color: #294A59;
                background: #FFFFFF;
                border: 0.5px solid #D7E0E5;
                box-shadow: 0px 1px 4px rgba(41, 74, 89, 0.15);
                border-radius: 10px;
            }

            &:hover{
                color: #294A59;
            }
        }

        &-Fullwidth{
            width: 100%!important;

            &-Button{
                font-family: 'Inter', sans-serif;
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                width: 50%;
                text-align:center;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &-Block{
            width: inherit;
        }
    }
}
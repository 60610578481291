@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - .02, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

@mixin make-container(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints,
  $container-gutters-use: $container-margin
) {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @each $breakpoint, $container-max-width in $max-widths {
    $valueGutter: map-get($container-gutters-use, $breakpoint);
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
      @if ($valueGutter != null) {
        padding-left: #{$valueGutter};
        padding-right: #{$valueGutter};
      }
    }
  }
}

@mixin make-row(
  $breakpoints: $grid-breakpoints,
  $grid-gutters-use: $grid-gutters
) {
  display: flex;
  flex-wrap: wrap;
  @each $breakpoint, $value in $breakpoints {
    $valueGutter: map-get($grid-gutters-use, $breakpoint);
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @if ($valueGutter != null) {
        margin-left: #{$valueGutter/-2};
        margin-right: #{$valueGutter/-2};
      }
    }
  }
}

@mixin make-col($size, $columns) {
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}

@mixin make-grid-columns(
  $columns: $grid-columns,
  $gutter: $grid-gutters,
  $breakpoints: $grid-breakpoints,
  $container-max-widths-use: $container-max-widths
) {
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px;
  }

  $use-key-gutter: 0;
  $use-column-count: 0;
  $breakpoint-grid: null;
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);
    $use-key-gutter: if(map_has_key($gutter, $breakpoint), map_get($gutter, $breakpoint), $use-key-gutter);
    $use-column-count: if(map_has_key($columns, $breakpoint), map_get($columns, $breakpoint), $use-column-count);
    $breakpoint-grid: if(map_has_key($columns, $breakpoint), $breakpoint, $breakpoint-grid);
    %-this {
      @if map_has_key($gutter, $breakpoint) {
        @include media-breakpoint-up($breakpoint) {
          padding-left: #{$use-key-gutter/2};
          padding-right: #{$use-key-gutter/2};
        }
      }
    }

    @for $i from 1 through $use-column-count {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
        @extend %-this;
      }
      .col#{$infix}-#{$i}-fix {
        @extend %grid-column;
        @extend %-this;
      }
    }
    .col#{$infix},
    .col#{$infix}-auto,
    .col#{$infix}-fix {
      @extend %grid-column;
      @extend %-this;
    }

    $next-breakpoint: breakpoint-next($breakpoint-grid, $columns);
    @if ($next-breakpoint != null) {
      $nextIndex: index(map_keys($breakpoints), $next-breakpoint);
      $next: nth(map_keys($breakpoints), $nextIndex - 1);
      @include media-breakpoint-between($breakpoint, $next, $breakpoints) {
        @for $i from 0 through $use-column-count {
          .col#{$infix}-#{$i} {
            @include make-col($i, $use-column-count);
          }
        }
      }
    } @else {
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        @for $i from 0 through $use-column-count {
          .col#{$infix}-#{$i} {
            @include make-col($i, $use-column-count);
          }
        }
      }
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; // Reset earlier grid tiers
      }
      @if map_has_key($container-max-widths-use, $breakpoint) {
        @for $i from 1 through $use-column-count {
          .col#{$infix}-#{$i}-fix {
            width: map_get($container-max-widths-use, $breakpoint)/$i;
            flex-basis: map_get($container-max-widths-use, $breakpoint)/$i;
          }
        }
      }

      .order#{$infix}-first { order: -1; }

      .order#{$infix}-last { order: $use-column-count + 1; }

      @for $i from 0 through $use-column-count {
        .order#{$infix}-#{$i} { order: $i; }
      }

      @for $i from 0 through ($use-column-count - 1) {
        @if not ($infix == "" and $i == 0) {
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $use-column-count);
          }
        }
      }
    }
  }
}

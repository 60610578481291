.ChangeLanguage{
    &-Panel{
        position: relative;

        &-Popup{
            position: absolute;
            top: 50px;
            right: 0px;
            background: #FFFFFF;
            border: 0.5px solid #D7E0E5;
            box-shadow: 0px 8px 32px rgba(11, 26, 18, 0.1);
            border-radius: 12px;
            z-index: 50;

            &-Item{
                display: block;
                text-decoration: none;
                border-bottom: 0.5px solid #D7E0E5;
                box-sizing: border-box;
                width: 168px;
                height: 32px;

                & button{
                    cursor: pointer;
                    border: none;
                    padding: 8px 12px;
                    background: none;
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    & img {
                        margin-left: 90px;
                    }
                }

                &:first-child{
                    border-radius: 12px 12px 0px 0px;
                }

                &:last-child{
                    border-bottom: none;
                    border-radius: 0px 0px 12px 12px;
                }
                &:hover{
                    background: #D7E0E5;
                    color: #000000;
                }
            }
        }
    }
    &-Item{
        color: #575757;

        &:hover{
            color: #000000;
        }

        &-Active{
            color: #B3B3B3;
            opacity: 1;

            &:hover{
                color: #B3B3B3;

                &:first-child{
                    border-radius: 12px 12px 0px 0px;
                }
            }
        }
    }
}
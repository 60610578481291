.root {
  max-width: 1440px;
  margin: 0 auto;
  padding: 34px 0;
}

@media (max-width: 1024px) {
  .root {
    padding: 5px 0;
  }
}

@media (max-width: 980px) {
  .root {
    max-width: 672px;
  }
}

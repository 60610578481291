.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.container {
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 100%;
  border: 2px solid transparent;
  border-top-color: #347757;
  border-radius: 50%;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: spin;
  animation-timing-function: linear;
}

.spinner::before {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 2px solid transparent;
  border-top-color: #fdb913;
  border-radius: 50%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: spin;
  animation-timing-function: linear;
  content: '';
}

.spinner::after {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  border: 2px solid transparent;
  border-top-color: #347757;
  border-radius: 50%;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: spin;
  animation-timing-function: linear;
  content: '';
}

@import "../global/grid.scss";
@import "../framework/grid.scss";

.FAQ{
    &-Container{
        margin: 0px auto;
        margin-bottom: 64px;

        @include media-breakpoint-up(md) {
            margin: 32px 36px 64px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 640px;
            margin: 32px auto 96px;
        }

        @include media-breakpoint-up(ulg) {
            margin: 32px auto 128px;
        }
    }

    &-Title{
        font-family: 'Montserrat Alternates', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 32px;
        text-align: center;
        color: #23312A;
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
            font-size: 28px;
            margin-bottom: 32px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 40px;
        }

        @include media-breakpoint-up(ulg) {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 64px;
        }
    }

    &-ListQuestions{
        background: #FAFBFC;
        border: 0.5px solid #CED1D0;
        border-radius: 24px;
        padding: 32px 24px;

        @include media-breakpoint-up(md) {
            padding: 32px;
        }
    }

    &-List{
        background: #FFFFFF;
        border: 0.5px solid #E7E8E7;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        margin-bottom: 16px;
        padding: 18px 24px 18px 24px;

        &-Question{
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
        }

        &-ExpandIcon{
            animation: rotation 2s infinite linear;
        }

        &-Answer{
            overflow: hidden;
            transition: all 0.2s ease;
            opacity: 0;
            max-height: 0;

            &-Content{
                border-top: 1px solid #E7E8E7;
                margin-top: 18px;
                padding-top: 18px;
            }
        }
    }

    &-List-Opened .FAQ-List-ExpandIcon{
        transform: rotate(180deg);
    }

    &-List-Opened .FAQ-List-Answer{
        max-height: 1000px;
        opacity: 1;
    }
}
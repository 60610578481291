.root {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  margin: 0 auto;
  max-width: 1120px;
}

@media (max-width: 1180px) {
  .root {
    margin: 0 24px;
  }
}

@media (max-width: 920px) {
  .root {
    margin: 0 auto;
    max-width: 672px;
    padding: 14px 0;
  }
}

@media (max-width: 724px) {
  .root {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 22px;
    max-width: 100%;
    padding: 7px 0;
  }
}

.root {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 34px;
  max-width: 352px;
}

.title {
  margin: 0 0 32px;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}

.list {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  display: block;
  margin: 0 0 32px;
  padding: 0 0 32px;
  box-shadow: inset 0 -0.5px 0 0 var(--color-N150);
}

.date {
  margin-bottom: 8px;
  color: var(--color-N700);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.itemTitle {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.itemLink {
  color: var(--color-B1000);
  text-decoration: none;
}

.itemLink:hover {
  text-decoration: underline;
}

@media (max-width: 1180px) {
  .root {
    max-width: 304px;
  }

  .title {
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 36px;
  }

  .item {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }

  .date {
    font-size: 12px;
  }

  .itemTitle {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 920px) {
  .root {
    max-width: 232px;
  }
}

@media (max-width: 724px) {
  .root {
    max-width: 100%;
  }
}

@media (max-width: 420px) {
  .title {
    font-size: 24px;
    line-height: 32px;
  }
}

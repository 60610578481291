.Dropdown-Panel-Popup {
  position: absolute;
  top: 50px;
  z-index: 50;
  border-radius: 12px;
  border: 0.5px solid #d7e0e5;
  background: #ffffff;
  box-shadow: 0 8px 32px rgba(11, 26, 18, 0.1);
}

.Dropdown-Panel-Popup-Item {
  display: block;
  text-decoration: none;
  width: 100%;
  padding: 12px;
  color: inherit;
}

.Dropdown-Panel-Popup-Item-bordered {
  border-bottom: 0.5px solid #d7e0e5;

  & button {
    cursor: pointer;
    border: none;
    padding: 8px 12px;
    background: none;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &:first-child {
    border-radius: 12px 12px 0 0;
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 12px 12px;
  }

  &:hover {
    background: #d7e0e5;
    color: #000000;
  }
}

.Dropdown-Item {
  color: #575757;

  a &:hover {
    color: #000000;
  }
}

.Dropdown-Item-Active {
  color: #b3b3b3;
  opacity: 1;

  &:hover {
    color: #b3b3b3;

    &:first-child {
      border-radius: 12px 12px 0 0;
    }
  }
}
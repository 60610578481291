@import "global/grid.scss";
@import "framework/grid.scss";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
}

html, body {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.AppContent {
    flex: 1 0 auto;
}

.footer {
    flex: 0 0 auto;
}


.Block{
    width: 100%;
    &-Container{

        @include media-breakpoint-up(ulg) {
            &-Centered{
                margin-left: auto!important;
                margin-right: auto!important;
            }
        }
    }
}

@include media-breakpoint-up(ulg) {
    .Block{
        margin-left: inherit;
        &-Container{
            width: 1120px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .Block{
        margin-left: inherit;
        &-Container{
            width: 1120px;
        }
    }
}

.container .btn:hover {
	background: #0097e6;
}

nav {
	background: #fff;
    margin: 12px 24px;

    @include media-breakpoint-up(md) {
        margin: 20px 32px;
    }

    @include media-breakpoint-up(lg) {
        margin: 40px 40px 64px;
    }

    @include media-breakpoint-up(ulg) {
        margin: 40px 40px 80px;
    }

    @include media-breakpoint-up(xl) {
        margin: 40px 160px 80px;
    }

    @include media-breakpoint-up(hd) {
        margin: 40px 400px 80px;
    }
}

nav .nav-items {
	display: flex;
	justify-content: space-between;
	height: 100%;
	align-items: center;
}

.nav-items ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nav-items ul li {
	list-style: none;
	margin-left: 20px;
	font-size: 18px;
	font-weight: 600;
	color: #000;
}

.nav-items ul li a {
	color: #000;
}

.nav-items ul li a:hover {
	color: #00a8ff;
}

.nav-items ul li a.active {
	color: #00a8ff;
}

.footer{
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #294A59;
    padding: 24px;
    color: #7492A3;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 100%;

    & .Block-Container{
        &-Top{
            display: inline-flex;
            justify-content: space-between;
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;
            width: 100%;

            &-Menu{
                & ul li{
                    display: block;
                    padding: 9px 0px;
                }

                & a{
                    font-family: 'Inter', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    color: #9BB1BD;
                    text-decoration: none;

                    &:hover{
                        color: #38637B;
                    }

                    @include media-breakpoint-up(md) {
                      font-size: 15px;
                    }

                    @include media-breakpoint-up(lg) {
                      font-size: 16px;
                    }
                }

            }
        }

        &-Top-Logo{
            padding-bottom: 16px;

            @include media-breakpoint-up(md) {
                padding-bottom: 0px;
            }

            @include media-breakpoint-up(lg) {
                background: url(../static/footerLogo.svg);
                background-repeat: no-repeat;
                background-size: 200px 40px;
                width: 200px;
                height: 40px;
            }
        }

        &-Top-Logo-Image{
            width: 42px;
            height: 30px;
            background: url(../static/mobileFooterLogo.svg);

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }


    & .container {
        padding: 0px;
    }

    &-delimeter{
        height: 1px;
        background: #4C7388;
        margin: 24px 0px;

        @include media-breakpoint-up(md) {
            margin: 32px 0px;
        }
    }

    &-legalNotice{
      font-size: 12px;
      margin-bottom: 16px;
    }

    &-infoNotice{
        font-size: 12px;
        margin-bottom: 16px;
    }

    &-copyright{
        font-size: 12px;
    }

}

@include media-breakpoint-up(md) {
    .footer{
        & .Block-Container{
            padding: inherit;
            &-Top{
                display: inline-flex;
                justify-content: space-between;
                flex-direction: row;
                align-content: flex-start;
                align-items: center;
                width: 100%;

                &-Menu{
                    & ul li{
                        display: inline-block;
                        padding: inherit;

                        @include media-breakpoint-up(md) {
                            margin: 0 16px;

                            &:first-child{
                                margin-left: 0;
                            }

                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.error-page{
    padding: 200px;
    text-align: center;
}

.root {
  display: flex;
  padding-top: 3px;
  align-items: center;
  flex-flow: column;
  justify-content: center;
}

.title {
  margin: 0;
  padding-bottom: 10px;
  color: #23312A;
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
}

.description {
  color: #545F59;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  text-align: center;
}

@media (max-width: 1024px) {
  .title {
    font-size: 28px;
    line-height: 36px;
    padding-bottom: 8px;
  }
}

@media (max-width: 868px) {
  .root {
    padding-top: 1px;
  }

  .title {
    padding-bottom: 7px;
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 744px) {
  .root {
    padding: 12px 24px 0;
  }

  .title {
    padding-bottom: 4px;
  }

  .description {
    font-size: 14px;
    line-height: 20px;
  }
}

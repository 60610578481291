.root {
  display: flex;
  justify-content: flex-start;
  padding: 2px 0 18px;
  margin: 0 auto;
  max-width: 1120px;
}

@media (max-width: 980px) {
  .root {
    margin: 0 24px;
  }
}

@media (max-width: 920px) {
  .root {
    margin: 0 auto;
    max-width: 672px;
  }
}

@media (max-width: 724px) {
  .root {
    max-width: 100%;
    margin: 0 24px;
  }
}


@import "../global/grid.scss";
@import "../framework/grid.scss";

.SwitchContent-Title{
  font-family: 'Montserrat Alternates', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #23312A;
  margin: 0px 40px 64px;

  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(ulg) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 64px;
  }
}

.SwitchContent{
    background: rgba(235, 239, 242, 0.25);
    border: 1px solid #EBEFF2;
    border-radius: 24px;
    padding: 24px;
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
        margin: 0px 36px;
        padding: 32px;
    }
    @include media-breakpoint-up(lg) {
        margin: 0px 40px;
        padding: 64px;
    }

    @include media-breakpoint-up(ulg) {
        margin: 0px 80px;
        padding: 86px 80px;
    }

    @include media-breakpoint-up(xl) {
        margin: 0px 160px;
    }

    @include media-breakpoint-up(hd) {
        margin: 0px 400px;
    }

    &-SlideContent{
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &-Right img{
            width: 272px;
            height: auto;
        }

        @include media-breakpoint-up(md) {
            &-Right img{
                width: 240px;
                height: auto;
            }
        }
        @include media-breakpoint-up(lg) {
            &-Right img{
                width: 356px;
                height: auto;
            }
        }

        @include media-breakpoint-up(ulg) {
            &-Right img{
                width: 416px;
                height: auto;
            }
        }

    }

    @include media-breakpoint-up(md) {
        &-SlideContent{
            flex-direction: row-reverse;
        }
    }


    &-Item{
        &-Title{
            font-family: 'Montserrat Alternates', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 40px;
            color: #0B1A12;
            margin-top: 24px;
            text-align: center;
        }

        &-Subtitle{
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 28px;
            color: #545F59;
            margin-top: 8px;
            text-align: center;
        }

        @include media-breakpoint-up(md) {
            &-Title{
                text-align: left;
            }

            &-Subtitle{
                text-align: left;
            }
        }

        @include media-breakpoint-up(lg) {
            &-Title{
                font-size: 28px;
            }

            &-Subtitle{
                font-size: 18px;
            }
        }

        @include media-breakpoint-up(ulg) {
            &-Title{
                font-size: 32px;
            }

            &-Subtitle{
                font-size: 20px;
            }
        }

        &-NumberedList{
            margin-top: 40px;
            width: 100%;
        }

        &-MapButton{
            margin-top: 40px;
        }
    }
}

.popup {
  position: fixed;
  z-index: 2;
  border-radius: 16px;
  box-shadow:
    inset 0 0 0 0.5px var(--color-B200),
    0 24px 96px 0 #00000080;
  background-color: #FAFBFC;
  top: auto;
  right: 16px;
  bottom: 16px;
  left: 16px;
  transform: none;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: #000;
  opacity: .3;
}

@media (min-width: 744px) {
  .popup {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
    right: auto;
    border-radius: 24px;
  }
}

.banner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  position: sticky;
  padding: 24px;
  top: 0px;
  background-color: #F5EAEA;
  z-index: 1001;
}

.link {
  color: #9A2B2E;
  cursor: pointer;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.text {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.alert {
  width: 24px;
  height: 24px;
}

.close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
}

.icon {
  color: #9A2B2E;
  fill: currentColor;
}

@media (min-width: 1024px) {
  .banner {
    margin: 40px 40px 20px;
    border-radius: 16px;
    top: 40px;
  }
}

@media (min-width: 1920px) {
  .banner {
    margin: 40px 160px 20px;
    border-radius: 16px;
  }
}

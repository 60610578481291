@import "../global/grid.scss";
@import "../framework/grid.scss";

.MobileApp{
  margin-bottom: 64px;

  @include media-breakpoint-up(md) {
    margin: 0px 36px 64px;
  }

  @include media-breakpoint-up(lg) {
    margin: 0px 40px 96px;
  }

  @include media-breakpoint-up(ulg) {
    margin: 0px 80px 128px;
  }

  @include media-breakpoint-up(xl) {
    margin: 0px 160px 128px;
  }

  @include media-breakpoint-up(hd) {
    margin: 0px 400px 128px;
  }
}

.MobileApp-Container{
  background: rgba(235, 239, 242, 0.25);
  border: 1px solid #EBEFF2;
  border-radius: 24px;
  padding: 24px;
  margin-bottom: 24px;
  display: grid;
  justify-items: center;
  gap: 32px;
  grid-template-areas:
    "subtitle"
    "numberedList"
    "image"
    "mapButtons";

  @include media-breakpoint-up(md) {
    padding: 32px;
    gap: 20px 40px;
    grid-template-areas:
      "numberedList image"
      "mapButtons image";
  }

  @include media-breakpoint-up(lg) {
    gap: 24px 40px;
    padding: 64px;
  }

  @include media-breakpoint-up(xl) {
    gap: 32px 64px;
  }

  @include media-breakpoint-up(ulg) {
    padding: 80px;
  }
}

.MobileApp-Title{
  font-family: 'Montserrat Alternates', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #23312A;
  margin: 0px 40px 64px;

  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(ulg) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 64px;
  }
}

.MobileApp-Subtitle{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #0B1A12;
  text-align: center;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  grid-area: subtitle;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.MobileApp-NumberedList{
  width: 100%;
  grid-area: numberedList;
}

.MobileApp-MapButtons{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
  grid-area: mapButtons;
  width: 100%;
  max-width: 296px;
  max-height: 40px;

  @include media-breakpoint-up(md) {
    justify-self: left;
    max-width: 312px;
  }

  .Button-appStore {
    width: auto;
  }

  .Button-googlePlay {
    width: auto;
  }
}

.MobileApp-ImageContanter{
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-area: image;
}

.MobileApp-ImageContanter-Image{
  width: 272px;
  height: 260px;

  @include media-breakpoint-up(md) {
    width: 260px;
    height: 260px;
  }

  @include media-breakpoint-up(lg) {
    width: 356px;
    height: 356px;
  }

  @include media-breakpoint-up(xl) {
    width: 416px;
    height: 416px;
  }
}

@import "../global/grid.scss";
@import "../framework/grid.scss";

.Features3rows-Container {
  margin: 0px 24px;

  @include media-breakpoint-up(md) {
    margin: 0px 36px;
  }

  @include media-breakpoint-up(lg) {
    margin: 0px 40px;
  }

  @include media-breakpoint-up(ulg) {
    margin: 0px 80px;
  }

  @include media-breakpoint-up(xl) {
    margin: 0px 160px;
  }

  @include media-breakpoint-up(hd) {
    margin: 0px 400px;
  }
}

.Features3rows-Title {
  font-family: 'Montserrat Alternates', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #23312A;
  margin: 64px 0px 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;

  @include media-breakpoint-up(md) {
    font-size: 28px;
    margin-bottom: 32px;
    align-items: center;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    line-height: 40px;
    margin: 96px 0px 40px;
    gap: 16px;
  }

  @include media-breakpoint-up(ulg) {
    font-size: 32px;
  }
}

.Features3rows-Logo {
  display: block;
  width: auto;
  height: 32px;
  margin-bottom: 12px;

  @include media-breakpoint-up(md) {
    width: 200px;
    height: 40px;
  }
}

.Features3rows-LongDash {
  display: none;

  @include media-breakpoint-up(lg) {
    display: inherit;
  }
}

.Features3rows-Items {
  display: grid;
  margin-bottom: 64px;
  gap: 16px;
  grid-template-columns: repeat(1, 1fr);

  @include media-breakpoint-up(md) {
    column-gap: 24px;
    row-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-up(ulg) {
    gap: 32px;
  }
}

.Features3rows-Item {
  padding: 24px;
  background-color: rgba(235, 239, 242, 0.25);
  border-radius: 24px;
  box-shadow: inset 0 0 0 0.5px var(--color-B200);

  @include media-breakpoint-up(ulg) {
    padding: 32px;
  }
}

.Features3rows-Item-Icon {
  display: block;
  margin-bottom: 24px;

  @include media-breakpoint-up(ulg) {
    margin-bottom: 32px;
  }
}

.Features3rows-Item-Title {
  margin-bottom: 8px;
  color: #0b1a12;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 32px;
  }

  @include media-breakpoint-up(ulg) {
    margin-bottom: 12px;
    font-size: 24px;
  }
}

.Features3rows-Item-Description {
  color: #23312A;
  font-size: 14px;
  line-height: 22px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 26px;
  }

  @include media-breakpoint-up(ulg) {
    font-size: 17px;
    line-height: 28px;
  }
}

@import "../global/grid.scss";
@import "../framework/grid.scss";

ol.CustomNumberedList {
  list-style: none;
  counter-reset: my-awesome-counter;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  & li.CustomNumberedList{
    counter-increment: my-awesome-counter;
    width: 100%;
    font-style: normal;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    margin-bottom: 12px;
    justify-content: flex-start;
    margin-bottom: 12px;

    &.CustomNumberedList-green::before{
        color: #347757;
        background: #EBF1EE;
        border: 1px solid #D6E4DD;
    }

    &.CustomNumberedList-orange::before{
        color: #AE7B08;
        background: #FBEBC6;
        border: 1px solid #F7D68D;
    }

    &.CustomNumberedList-midnight::before{
        color: #FFFFFF;
        background: #38637B;
        border: 1px solid #38637B;
        font-size: 14px;
    }

    &.CustomNumberedList-success::before{
        color: #FFFFFF!important;
        background: #347757!important;
        border: 1px solid #347757!important;
        content: url(../../static/icons/success.svg)!important;
    }

    &.CustomNumberedList-mobileApp{
      &:last-child{
        margin-bottom: 0;
      }

      & .CustomNumberedList-Sublist-Wrapper-mobileApp{
        @include media-breakpoint-up(lg) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }

    &.CustomNumberedList-mobileApp::before{
      @include media-breakpoint-up(lg) {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      @include media-breakpoint-up(xl) {
        width: 28px;
        height: 28px;
        margin-right: 12px;
      }
    }


    &::before{
        content: counter(my-awesome-counter);
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;

        border-radius: 20px;
        width: 26px;
        height: 26px;
        text-align: center;
        display: inline-flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
    }

    & .CustomNumberedList-Sublist-Wrapper{
        margin-top: 1px;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #23312A;
        width: calc(100% - 28px);

        @include media-breakpoint-up(lg) {
          font-size: 16px;
          line-height: 24px;
        }

        @include media-breakpoint-up(ulg) {
          font-size: 20px;
          line-height: 28px;
        }

        & .CustomNumberedList-Sublist-Wrapper-Item a {
          text-decoration: none;
          color: inherit;
          font-weight: 700;
        }
    }
  }
}

ul.CustomNumberedList-Sublist{
  list-style-type: disc;
  color: #545F59;
  margin-top: 8px;
  margin-left: 20px;
  margin-bottom: 0px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

@import "../global/grid.scss";
@import "../framework/grid.scss";

.SliderBlock{
    &-Container{
        margin: 24px 24px 40px;

        @include media-breakpoint-up(md) {
            margin: 32px 48px 48px;
        }

        @include media-breakpoint-up(lg) {
            margin: 64px 40px 64px;
        }

        @include media-breakpoint-up(ulg) {
            margin: 80px 80px;
        }

        @include media-breakpoint-up(xl) {
            margin: 80px 160px;
        }

        @include media-breakpoint-up(hd) {
            margin: 80px 400px;
        }

        &-Slide{
            width: 100%;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            align-content: center;
            flex-wrap: nowrap;

            &-Left{
                @include media-breakpoint-up(lg) {
                    padding-right: 32px;
                }

                @include media-breakpoint-up(ulg) {
                    padding-right: 64px;
                }
            }

            &-Image {
                padding-bottom: 40px;

                img{
                    height: 272px;
                    width: 272px;

                    @include media-breakpoint-up(md) {
                        height: 416px;
                        width: 416px;
                    }
                }
            }

            &-Title{
                font-family: 'Montserrat Alternates', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 44px;
                letter-spacing: -0.5px;
                color: #0B1A12;
                text-align: left;

                @include media-breakpoint-up(md) {
                    font-size: 34px;
                    line-height: 48px;
                    width: 100%;
                    max-width: 648px;
                    text-align: center;
                }

                @include media-breakpoint-up(lg) {
                    max-width: 496px;
                    text-align: left;
                    margin-top: 92px;
                }

                @include media-breakpoint-up(ulg) {
                    font-size: 44px;
                    line-height: 64px;
                    max-width: 640px;
                    margin-top: 60px;
                }
            }

            &-Subtitle{
              font-family: 'Inter', sans-serif;
              font-size: 20px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0px;
              text-align: left;
              color: #545F59;
              margin-top: 24px;

              @include media-breakpoint-up(md) {
                font-size: 24px;
                line-height: 36px;
                text-align: center;
              }

              @include media-breakpoint-up(lg) {
                text-align: left;
              }

              @include media-breakpoint-up(xl) {
                margin-top: 16px;
              }
            }

            &-Buttons{
              display: flex;
              gap: 8px;
              width: 100%;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: center;
              margin-top: 32px;

              @include media-breakpoint-up(md) {
                gap: 16px;
                margin-top: 40px;
              }

              @include media-breakpoint-up(lg) {
                justify-content: flex-start;
                max-width: 302px;
              }

              @include media-breakpoint-up(ulg) {
                max-width: 372px;
              }

              .Button-appStore {
                width: auto;
              }

              .Button-googlePlay {
                width: auto;
              }
            }

            @include media-breakpoint-up(lg) {
                width: 100%;
                display: inline-flex;
                flex-direction: row-reverse;
                align-items: flex-start;
                justify-content: space-around;
            }
        }

        &-Navigation{
            display: inline-flex;
            position: relative;
            z-index: 10;
            justify-content: center;
            width: 100%;
            margin: 49px auto;

            &-Pagination{
                margin: 0px 18px;
                display: inline-flex;
                align-items: center;
                height: 22px;
                width: auto!important;

                &-Bullet{
                    width: 12px;
                    height: 12px;
                    border: 2px solid #9BB1BD;
                    margin: 0px 4px;
                    display: inline-flex;
                    border-radius: 6px;

                    &-Active{
                        width: 16px;
                        height: 16px;
                        background: #38637B;
                        border-radius: 8px;
                        border: none;
                    }
                }
            }

            &-RightArrow, &-LeftArrow{
              cursor: pointer;
            }

            @include media-breakpoint-up(ulg) {
                &-RightArrow, &-LeftArrow{
                    display: inherit;
                }

                &-Pagination{
                    width: inherit!important;
                }
            }
        }

        @include media-breakpoint-up(ulg) {
            &-Navigation{
                display: inline-flex;
                position: absolute;
                bottom: 0px;
                width: inherit;
                margin: inherit;
            }
        }
    }
}

.root {
  max-width: 1440px;
  margin: 0 auto;
  padding: 34px 0;
}

@media (max-width: 1180px) {
  .root {
    padding-top: 14px;
  }
}

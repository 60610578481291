.root {
  width: 100%;
  max-width: 640px;
  margin-bottom: 34px;
  box-sizing: border-box;
}

.imageBox {
  display: flex;
  max-width: 640px;
  max-height: 360px;
  margin-bottom: 32px;
  border-radius: 16px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
}

.image {
  box-sizing: border-box;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.date {
  margin-bottom: 16px;
  color: #545F59;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.title {
  margin: 0 0 31px;
  color: #0B1A12;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.content {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #545F59;
}

.content a {
  color: var(--color-N700);
  text-decoration: underline;
}

.content > p {
  margin-bottom: 28px;
}

.content > ol, .content > ul {
  padding-left: 25px;
  margin-bottom: 28px;

  ul {
    padding-left: 25px;
    list-style-type: disc;
  }
}

@media (max-width: 1180px) {
  .root {
    max-width: 544px;
  }

  .date {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 32px;
  }

  .imageBox {
    max-width: 544px;
    max-height: 320px;
  }

  .image {
    height: 320px;
  }

  .content {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 920px) {
  .root {
    max-width: 408px;
  }

  .date {
    font-size: 12px;
    line-height: 16px;
  }

  .imageBox {
    max-width: 544px;
    max-height: 256px;
  }

  .image {
    height: 256px;
  }
}

@media (max-width: 724px) {
  .root {
    max-width: 100%;
  }

  .imageBox {
    max-width: 100%;
    max-height: 320px;
  }

  .image {
    height: 320px;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
  }

  .content > p {
    margin-bottom: 16px;
  }

  .content > ol, .content > ul {
    margin-bottom: 16px;
  }
}

@media (max-width: 420px) {
  .imageBox {
    max-width: 100%;
    max-height: 160px;
  }

  .image {
    height: 160px;
  }
}
